.container {
    /* position: absolute; */
    /* padding-top: 5rem; */
    bottom: 0;
    left: 0;
    width: calc(100% - 20px);
    margin: 0 10px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.page_btn {
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
    border-radius: 50%;
    outline: none;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    box-shadow: var(--box-shadow);
    cursor: pointer;
    background-color: white;
}

.active {
    background-color: #2C3E50;
    color: white;
}

@media screen and (max-width: 768px) {
    .page_btn {
        width: 30px;
        height: 30px;
    }
}