body {
  margin: 0;
  font-family: "Poppins";
}

:root {
  --primary-color: #0a66c2;
  --secondary-color: #ff2e2e;
  --hover-secondary: #2C3E50;
  --hover-primary: #d12929;
  --text-color: #34495E;
  --links-color: #3498DB;
}


.font-28 {
  font-size: 28px;
}

.font-72 {
  font-size: 72px;
}

.font-44 {
  font-size: 44px;
}

.font-24 {
  font-size: 24px;
}

.yellow-color {
  color: var(--secondary-color);
}

.dark-color {
  color: var(--primary-color);
}

.subscribe-heading {
  color: #b2b3cf;
  font-size: 18px;
  line-height: 27px;
}

.para-color {
  color: #696984;
}

.font-14 {
  font-size: 14px;
}

.primary-bg {
  background-color: var(--primary-color);
}

.hover-secondary:hover {
  color: var(--hover-secondary);
}

.hover-primary:hover {
  color: var(--hover-primary);
}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.poppins-regular {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
}

.login-bg {
  background-image: url("/src/assests/1-\ Create\ Account.png");
  background-position: top;
  background-size: cover;
  min-height: 100vh;
}

.body-bg {
  background-image: url("/src/assests/1-\ Create\ Account.png");
  background-position: top;
  background-size: cover;
}

.input-field {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  height: 48px;
}

.login-button {
  border-color: var(--primary-color);
}

.login-button:hover,
.login-button:active {
  background-color: var(--hover-secondary) !important;
  border-color: var(--hover-secondary) !important;
}

.sign-in-google-btn {
  background-color: #2d3748;
  border-color: #2d3748;
}

.sign-in-google-btn:hover,
.sign-in-google-btn:active {
  background-color: #2d3760 !important;
  border-color: #2d3760 !important;
}

.log-in-link:hover {
  color: var(--hover-secondary);
}

.custom-check input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.custom-check input {
  width: 20px;
  height: 20px;
  margin-top: unset;
}

.custom-check label {
  margin-left: 10px;
}

.otp-input input {
  width: 100% !important;
  height: 40px !important;
  margin-right: 1em;
}

.active-link {
  border-bottom: 2px solid var(--secondary-color);
  color: var(--secondary-color);
}

.bgimage {
  position: relative;
  height: 700px;
}

.hero {
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),
    url(../src/assests/hero-section.png);
  color: #ffffff;
  text-align: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  /* background-position: center; */
  background-position: 25% 28%;
  background-size: cover;
  /* background-attachment: fixed; */
}

.bg-overlay {
  height: 700px;
}

.bg-overlay::before {
  border-radius: 24px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #087643;
  opacity: 0.35;
}

.cognite-img-div {
  width: 775px;
  border-radius: 24px;
}

.Cognite-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 24px;
}

.image-responsive-cognite {
  width: 100%;
  height: 648px;
}

.image-responsive-cognite-contact {
  width: 100%;
  height: 100%;
}

.image-responsive-div {
  height: 700px;
}

/* footer {
  padding-top: 3em;
} */

.footer-07 {
  background: #252641;
}

.news-input {
  border-radius: 80px;
  border: 1px solid #83839a;
  padding: 12px 29px 12px 29px;
  font-size: 18px;
  line-height: 27px;
  background-color: transparent;
}

.news-input::placeholder {
  color: #83839a;
  font-size: 18px;
}


.news-btn {
  border-radius: 60px;
  border-color: var(--primary-color);
  padding: 12px 32px;
  background: linear-gradient(105.5deg, var(--primary-color) 19.57%, var(--secondary-color) 78.85%);
  font-size: 17px;
  color: white;
  transition: all 1s ease-out;
  border: 2px solid;
  cursor: pointer;
}

.news-btn:hover {
  border-color: var(--primary-color);
  background: linear-gradient(105.5deg, var(--secondary-color) 19.67%, var(--primary-color) 78.95%);
  transition: all 1s ease-in;
}

.Footer-link {
  font-size: 15px;
  line-height: 22px;
  color: #b2b3cf;
  letter-spacing: 2px;
}

.link-btn:hover {
  color: #dadae7;
}

.line {
  background-color: #626381;
  width: 1px;
  height: 1.3rem;
}

.bg-yellow {
  background-color: #ffeee8;
}

.svg-size {
  width: 40px;
  height: 40px;
}

.teaching-cognity-heading {
  font-size: 40px;
  line-height: 48px;
  color: #1d2026;
}

.teaching-para {
  font-size: 18px;
  line-height: 24px;
  color: #4e5566;
}

.card-heading {
  font-size: 18px;
}

.custom-card-dashboard {
  height: 237px;
  box-shadow: 8px 8px 48px 8px #00000014;
  border-radius: 24px;
  border: unset;
}

.custom-card {
  border-radius: 6px;
  border: unset;
}

.card-text {
  font-size: 14px;
  line-height: 22px;
}

.bg-brown {
  background-color: #f5f7fa;
}

.points {
  font-size: 14px;
  line-height: 22px;
  color: #1d2026;
}

.email-head {
  font-size: 12px;
  line-height: 12px;
  color: #8c94a3;
}

.email-para {
  font-size: 18px;
  line-height: 24px;
  color: #1d2026;
}

.custom-card-bg {
  background-color: #ffeee8;
  border: unset;
}

.arrow-left-btn {
  background-color: #f5f7fa;
  padding: 12px;
  border-radius: unset;
  border-color: unset;
}

.arrow-left-btn:hover,
.arrow-left-btn:active {
  background-color: #c3c5c7 !important;
  border-color: #c3c5c7 !important;
}

.arrow-right-btn,
.All-blogs {
  background-color: var(--primary-color);
  padding: 12px;
  border-radius: unset;
  border-color: unset;
  box-shadow: 0px 6px 20px 0px var(--secondary-color);
}

.arrow-right-btn:hover {
  background-color: var(--hover-secondary);
  border-color: var(--hover-secondary);
}

.arrow-right-btn:active {
  background-color: var(--hover-secondary) !important;
  border-color: var(--hover-secondary) !important;
}

.custom-carousel {
  position: relative;
}

.custom-carousel-item {
  transition: transform 0.5s ease-in-out, height 0.5s ease-in-out;
}

.custom-carousel-item .custom-card-bg {
  height: 200px;
  /* Set a fixed height for consistency */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Ensure smooth transition */
.custom-carousel-item .card-text {
  transition: opacity 0.5s ease-in-out;
}

.contact-heading {
  padding-left: 20px;
  display: inline;
  font-size: 35px;
  color: #303a42;
  background-image: url("/src/assests/Ellipse\ 2.svg");
  background-size: 73px;
  background-repeat: no-repeat;
  background-position: start;
  background-position: -7%;
}

.custom-form input {
  padding: 20px 30px 20px 30px;
  background-color: #0000000a;
}

.custom-form textarea {
  padding: 20px 30px 20px 30px;
  background-color: #0000000a;
}

.hr-line {
  width: 80px;
  border: 1px solid var(--secondary-color);
}


.testi {
  letter-spacing: 4px;
  font-family: "Nunito Sans";
  font-size: 20px;
}

.testi-head {
  font-family: "Nunito Sans";
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 60px;
}

.asess-btn {
  border-radius: 80px;
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  background-color: transparent;
}

.image-responsive-testimonial {
  width: 560px;
  height: 700px;
}

.asess-btn:hover {
  border: 1px solid var(--secondary-color);
  color: white;
  background-color: var(--secondary-color);
}

.asess-btn:hover .assesment-bg {
  border-left: 1px solid var(--hover-primary);

}

.asess-btn:hover svg path {
  fill: white
}

.testi-card {
  box-shadow: 2px 4px 60px 0px #292c7c1a;
  border-color: transparent;
  border-radius: 20px;
  border-left: 14px solid #f67766;
}

.btn-right {
  display: inline-block;
  margin-left: -70px;
}

.card-text-testi {
  font-family: "Nunito Sans";
  font-size: 22px;
  color: #5f5f7e;
  line-height: 39.6px;
  border-left: 1px solid #bdbdd1;
}

.comment-name {
  font-size: 24px;
  font-family: "Nunito Sans";
  color: #5f5f7e;
  line-height: 43.2px;
}

.comment-text {
  font-family: "Nunito Sans";
  color: #5f5f7e;
  font-size: 18px;
  line-height: 32.4px;
}

.review-card {
  /* width: 680px; */
  margin-top: -210px;
  margin-left: 200px;
}

.btn-right img {
  width: 150px;
  height: 150px;
}

.term-border {
  border-bottom: 1px solid #c9c9ca;
}

.terms-heading {
  font-size: 25px;
  line-height: 39px;
  color: #656565;
}

.terms-text {
  font-size: 22px;
  line-height: 39px;
  color: black;
}

.main-dislay {
  width: 100%;
  /* padding: 20px; */
}

.sidebar {
  background: #faf7fe;
  color: #656565;
  height: 100vh;
  /* min-width: 330px; */
  transition: all 0.5s;
}

/* .header-transition {
  transition: all 0.5s;
} */

.top_section {
  background-color: #faf7fe;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 15px;
  border-right: 1px solid #e3e0e0;
}

.logo-heading {
  cursor: pointer;
  font-size: 36px;
  font-family: sans-serif;
  font-weight: 800;
  line-height: 49.03px;
  color: #000000;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.link {
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
  color: #656565;
  padding: 14px 40px;
  gap: 15px;
  transition: all 0.5s;
}

.link:hover {
  /* background: var; */
  color: var(--primary-color);
  transition: all 0.5s;
}

.activeLink .active {
  /* background: lightskyblue; */
  color: var(--secondary-color);
  border-right: 4px solid var(--secondary-color);
}

.offcanvas-body .active {
  /* background: lightskyblue; */
  color: var(--primary-color) !important;
  border-right: 4px solid var(--primary-color) !important;
}

.header__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  gap: 15px;
  /* margin-bottom: 15px; */
}

.header {
  /* padding-top: 20px; */
  border-bottom: 1px solid #e3e0e0;
}

.notification-bell-icon {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  border: none;
  background: #fafafa;
  cursor: pointer;
}

.custom-dropdown:active,
.custom-dropdown:hover,
.custom-dropdown.show {
  border-color: transparent !important;
}

.custom-item:hover {
  background-color: #faf7fe;
  color: var(--primary-color);
}

.custom-card-overlay {
  background-repeat: no-repeat;
  /* width: 490px; */
  background-size: cover;
  height: 434px;
  border-radius: 48px;
  border-color: unset;
  border: unset;
  position: relative;
  overflow: hidden;
}

.min-Height {
  height: 295px !important;
}

.navbar-brand {
  height: 49px;
}

.custom-card-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.card-bg-overlay {
  background-color: #9425394d;
  border-radius: 24px;
  position: relative;
  position: relative;
}

.card-bg-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  /* z-index: 1; */
}

.custom-card-overlay img {
  position: relative;
  z-index: 2;
}

.custom-card-overlay .card-content {
  position: relative;
  z-index: 2;
}

.profil-img {
  width: 48px;
  height: 48px;
}

.footer-logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.footer-logo-img {
  height: 84px;
  width: 350px;
}

.count-filter {
  font-size: 12px;
  line-height: 16px;
  width: 20px;
  height: 24px;
}

.select-form .form-select {
  padding: 0.375rem 4.25rem 0.375rem 0.75rem;
}

.search-input:focus {
  box-shadow: unset;
}

.search-input-group {
  border-radius: 6px !important;
  border: 1px solid #e9eaf0;
}

.filter-btn {
  height: 45px;
  border-radius: 6px;
  border: 1px solid var(--primary-color);
}

.sort-heading {
  font-size: 14px;
  line-height: 22px;
}

.text-color-light {
  color: #4e5566;
}

.selection {
  font-size: 16px;
  line-height: 24px;
  color: #4e5566;
}

.accordian-custom {
  border: 1px solid #e9eaf0;
}

.accordian-custom .accordion-button:focus {
  box-shadow: unset;
}

.accordian-custom .accordion-button:not(.collapsed) {
  border: 1px solid #e9eaf0;
  background-color: transparent;
  padding-bottom: var(--bs-accordion-btn-padding-y);
}

.accordion-custom-image .accordion-button {
  padding-bottom: unset;
}

.accordion-custom-image {
  /* border-bottom: 1px solid grey; */
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-custom-image .accordion-button:not(.collapsed) {
  border: unset !important;
  border-top: 1px solid #e9eaf0 !important;
  background-color: transparent;
  box-shadow: none;
}

.count {
  font-size: 12px;
  line-height: 14px;
  color: #8c94a3;
}

.Custom-header-accordian button {
  text-transform: uppercase;
}

.course-heading {
  font-size: 32px;
  line-height: 40px;
  color: #1d2026;
}

.course-sub {
  font-size: 20px;
  line-height: 32px;
  color: #4e5566;
}

.created {
  font-size: 14px;
  line-height: 22px;
  color: #6e7485;
}

.video-placeholder {
  position: relative;
}

.Player-btn {
  position: absolute;
  top: 39%;
  left: 46%;
}

.started-bg {
  background-color: rgba(0, 0, 0, 0.16);
  /* background-color: red; */
  overflow: hidden;
}

.assesment-bg {
  border-radius: 80px;
  border-left: 1px solid var(--secondary-color);
  background-color: transparent;
  overflow: hidden;
}

.assesment-bg svg path {
  fill: var(--secondary-color);
}

.started-btn {
  background-color: #0a66c2;
  border-color: transparent;
}

.started-btn:hover {
  background-color: var(--hover-secondary);
}

.start-btn-text {
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
}

.tab-text-width {
  max-width: 900px;
}

.tab-text-width .nav-link {
  text-align: left;
  border-bottom: unset !important;
}

.tab-text .nav-link.active {
  color: var(--secondary-color) !important;
  border: unset !important;
  border-bottom: 2px solid var(--secondary-color) !important;
}

.tab-text .nav-link {
  border: unset;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #4e5566 !important;
  font-size: 16px;
  line-height: 22px;
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.tab-text {
  --bs-nav-tabs-border-radius: unset !important;
  --bs-nav-tabs-border-color: transparent !important;
}

.tab-text .nav-tabs {
  border: unset !important;
}

.course-Subheading {
  font-size: 24px;
  line-height: 32px;
  color: #1d2026;
}

.course-content {
  font-size: 14px;
  line-height: 22px;
  color: #4e5566;
}

.Course-card {
  background-color: #e1f7e366;
  border-color: transparent;
}

.accordion-button-custome::after {
  display: block !important;
}

.custome-accordian-btn .accordion-button::after {
  display: none;
  margin-left: unset;
}

.custome-accordian-btn .accordion-button {
  display: inline-block;
}

.accordion-button-custome {
  padding: unset;
  width: max-content;
  border-color: unset;
  border: unset;
}

.accordion-button-custome:not(.collapsed) {
  border: unset !important;
  padding-bottom: unset !important;
  box-shadow: unset;
}

.content-bg {
  background-color: #f5f7fa;
}

.lecture {
  font-size: 14px;
  line-height: 22px;
  color: #4e5566;
}

.border-up {
  border-top: 1px solid #e9eaf0;
}

.border-down {
  border-bottom: 1px solid #e9eaf0;
}

.custom-tabs ul {
  margin-bottom: unset !important;
}

.download-btn {
  background-color: #9e5cf21a;
  border-color: transparent;
  color: var(--primary-color);
  margin-bottom: 8px;
}

.download-btn:hover,
.download-btn:active {
  background-color: var(--hover-secondary) !important;
  border-color: transparent !important;
}

.review-btn:hover,
.review-btn:active {
  background-color: #9e5cf21a !important;
  border-color: transparent !important;
  color: var(--primary-color) !important;
}

.login-button:disabled {
  background-color: var(--primary-color);
  border-color: transparent;
}

.completion-text {
  font-size: 16px;
  line-height: 22px;
  color: #23bd33;
}

.custom-progress {
  height: 4px;
}

.custom-progress .progress-bar {
  background-color: #23bd33;
}

.accordian-title {
  font-size: 18px;
  line-height: 22px;
  color: #1d2026;
}

.accordian-text {
  font-size: 14px;
  line-height: 22px;
  color: #4e5566;
}

.cancel-btn {
  background-color: #f5f7fa;
  color: #1d2026;
  border: unset;
  font-size: 16px;
  font-weight: 600;
}

.feedback-header {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #1d2026;
}

.feedback-label {
  font-size: 14px;
  line-height: 22px;
  color: #1d2026;
}

.menu-btn {
  border-color: var(--primary-color);
}

.menu-btn:hover,
.menu-btn:active {
  background-color: var(--primary-color) !important;
  border-color: transparent;
}

.Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.outer-div-img {
  width: 280px;
  height: 280px;
  overflow: hidden;
}

.camera-icon {
  margin-top: -35px;
}

.Upload-btn {
  width: max-content;
  background-color: #00000080;
  color: white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-color: transparent;
  border-radius: 1px;
}

.Upload-btn:hover,
.Upload-btn:active {
  background-color: #00000099 !important;
  border-color: transparent !important;
}

.branch-heading {
  font-size: 40px;
  color: #1d2026;
}

.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.min-img-size-upload {
  width: 100%;
  height: 196px;
  overflow: hidden;
  border-radius: 10px;
  transition: 0.3s all;
  /* cursor: pointer; */
}

.min-img-size-upload-video {
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 2px;
  transition: 0.3s all;
}

.min-img-size-upload-video:hover {
  transform: scale(1.03);
}

.min-img-size-upload:hover {
  transform: scale(1.03);
}

.min-img-size {
  width: 100%;
  height: 382px;
  overflow: hidden;
  transition: 0.3s all;
}

.min-img-size:hover {
  transform: scale(1.03);
}

.img-card {
  border-radius: unset;
  background-color: #ffffff;
  border: 1px solid #e9eaf0 !important;
}

.card-img-main {
  position: relative;
  bottom: 135px;
  /* margin-top: -140px; */
}

.card-img-text {
  font-size: 18px;
  line-height: 24px;
}

.contact-bg {
  background-color: #f5f7fa;
}

.address-heading {
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.adress-content {
  color: #1d2026;
  font-size: 18px;
  line-height: 24px;
}

.adress-text {
  font-size: 18px;
  line-height: 24px;
  color: #8c94a3;
}

.map {
  height: 424px;
  /* background-image: url("https://maps.googleapis.com/maps/api/staticmap?center=34.0881669,-118.5531907&zoom=5&size=640x320&scale=2&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyDFh1g2GhFvl3b_T8G__6C7Qh0pNo_8PdI"); */
  background-image: url("https://maps.googleapis.com/maps/api/staticmap?center=34.0881669,-118.5531907&zoom=4&size=640x320&scale=2&maptype=roadmap&markers=color:blue%7Clabel:S%7C34.0881669,-118.5531907&key=AIzaSyDFh1g2GhFvl3b_T8G__6C7Qh0pNo_8PdI");
  background-repeat: no-repeat;
  background-size: cover;
  /* object-fit: contain; */
}

.card-text-light {
  font-size: 16px;
  line-height: 24px;
  color: #00000080;
}

.course-Subheading-small {
  font-size: 16px;
  line-height: 22px;
  color: #1d2026;
}

.save-btn-text {
  border: unset !important;
}

.input-field {
  padding-right: 70px;
}

.char-count {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 15px;
  color: #4e5566;
}

.course-para-light {
  font-size: 16px;
  line-height: 22px;
  color: #6e7485;
}

.tox-statusbar {
  display: none !important;
}

.icon-color-delte {
  cursor: pointer;
  color: #8c94a3;
  font-size: 20px;
}

.icon-color-delte:hover {
  color: red;
}

.menu-btn:disabled {
  background-color: var(--primary-color) !important;
  border-color: transparent !important;
}

.icon-color {
  cursor: pointer;
  color: #8c94a3;
  font-size: 20px;
}

.icon-color:hover {
  color: var(--primary-color);
}

.form-check-input:checked {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.custom-offcanvas {
  width: 500px !important;
}

.bg-curriculum {
  background-color: #f5f7fa;
  border-radius: 6px;
}

.lecture-bg {
  background-color: #ffffff;
}

.form-height {
  height: 103px;
}

.Toastify__toast-theme--light {
  background: var(--primary-color) !important;
  color: #ffffff !important;
}

.Toastify__toast-container button {
  margin: auto;
}

.avatar {
  height: 34px;
  width: 34px;
}

.avatar img {
  /* border-radius: 60px; */
  width: 34px;
  height: 34px;
  border-radius: 50%;
  object-fit: cover;
}

.calender-input input {
  /* border: none; */
  box-sizing: border-box;
  /* outline: 0; */
  /* padding: .75rem; */
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.cutom-modal-body {
  height: 590px;
}

.sidebar-small {
  width: 300px !important;
}

.landing-side {
  width: 300px !important;
}

.sidecard .sidecard {
  font-size: 18px !important;
}

.sidecard .card-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #9d92cb !important;
}

.sidecard button {
  border-radius: 10px;
  background-color: var(--primary-color) !important;
  font-size: 14px;
  border-color: var(--primary-color);

}

.loader-block {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto 0;
  transform: translate(-50%, -50%);
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--primary-color);
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.loader-small {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--primary-color);
  width: 70px;
  height: 70px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.img-girl {
  margin-right: auto;
  margin-left: auto;
  width: 600px;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1460px) {
  /* .card-img-text {
    font-size: 15px;
    line-height: 24px;
  } */

  .change-column {
    flex: 0 0 auto !important;
    width: 50% !important;
  }

  .accordion-header button {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1200px) {
  .change-column {
    flex: 0 0 auto !important;
    width: 50% !important;
  }
}

@media screen and (max-width: 1025px) {
  .adress-content {
    font-size: 13px;
  }

  .address-heading {
    font-size: 15px;
  }

  .asess-btn {
    padding: 4px;
  }

  .card-img-main {
    padding: unset !important;
    position: static;
    /* margin-top: -140px; */
  }
}

@media screen and (max-width: 768px) {
  .cognite-img-div {
    width: unset;
  }

  .cutom-modal-body {
    height: unset;
  }

  .form-height {
    height: auto;
  }

  .change-column {
    flex: 0 0 auto !important;
    width: 100% !important;
  }

  .icon-color {
    font-size: unset;
  }

  .icon-color-delte {
    font-size: unset;
  }

  .custom-card-dashboard {
    height: unset;
  }

  .min-img-size-upload {
    width: 100%;
    height: 135px;
  }

  .tab-text-width {
    max-width: unset;
  }

  .img-girl {
    margin: auto;
    width: 100%;
    height: 348px;
  }

  .testi-card .card-body {
    padding: 6px;
  }

  .star-img {
    width: 16px !important;
    height: 16px !important;
  }

  .testi-card {
    border-left: 5px solid #f67766;
  }

  .adress-content {
    font-size: 13px;
  }

  .address-heading {
    font-size: 15px;
  }

  .card-img-main {
    position: static;
    /* margin-top: -140px; */
  }

  .map {
    height: 200px;
  }

  .min-img-size {
    width: 100%;
    height: 165px;
  }

  .outer-div-img {
    width: 168px;
    height: 168px;
  }

  .custome-accordian-btn button {
    padding: 4px;
  }

  .accordian-title {
    font-size: 14px;
  }

  .accordian-text {
    font-size: 10px;
  }

  .back-img {
    width: 38px;
    height: 38px;
  }

  .start-btn-text {
    font-size: 13px;
    font-weight: normal;
  }

  .download-btn-text {
    padding: 4px;
    font-size: 13px;
    font-weight: normal;
  }

  .download-btn {
    padding: 4px;
    font-size: 13px;
  }

  p {
    font-size: 15px !important;
  }

  .course-Subheading {
    font-size: 18px;
    line-height: unset;
  }

  .branch-heading {
    font-size: 20px;
    line-height: unset;
  }

  .course-heading {
    font-size: 20px;
    line-height: unset;
  }

  .course-sub {
    font-size: 15px;
    line-height: normal;
  }

  .main-dislay {
    padding: unset;
  }

  .tab-text .nav-link.active {
    font-size: 14px;
    line-height: 10px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .tab-text .nav-link {
    font-size: 14px;
    line-height: 10px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .select-form .form-select {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  }

  .custom-carousel-item .custom-card-bg {
    height: auto;
  }

  .email-para {
    font-size: 15px;
  }

  .name-font {
    font-size: 10px !important;
  }

  .custom-card-overlay {
    height: 300px !important;
  }

  .notification-bell-icon {
    height: 28px !important;
    width: 40px !important;
  }

  .teaching-cognity-heading {
    font-size: 25px;
    line-height: normal;
  }

  .btn-right {
    display: inline-block;
    margin-left: -60px;
  }

  .comment-text {
    font-size: 10px;
    line-height: normal;
  }

  .comment-name {
    font-size: 12px;
    line-height: normal;
  }

  .card-text-testi {
    font-size: 13px;
    line-height: normal;
    margin-bottom: unset;
  }

  .review-card {
    width: auto;
    margin-top: -110px;
    margin-left: 10px;
  }

  .btn-right img {
    width: 100px;
    height: 100px;
  }

  .testi {
    font-size: 18px;
  }

  .testi-head {
    font-size: 24px;
  }

  .image-responsive-testimonial {
    width: auto;
    height: auto;
  }

  .hero {
    border-radius: unset;
  }

  .font-72 {
    font-size: 30px;
  }

  .font-24 {
    font-size: 18px;
  }

  .font-44 {
    font-size: 28px;
  }

  .bgimage {
    height: 450px;
  }

  .news-input {
    padding: 6px 12px;
    font-size: 18px;
  }

  .news-btn {
    padding: 6px 12px;
    font-size: 18px;
  }

  .news-input::placeholder {
    font-size: 18px;
  }

  .Footer-link {
    font-size: 16px;
    letter-spacing: unset;
  }

  .footer-logo-img {
    width: 70%;
  }

  .image-responsive-cognite {
    width: 100%;
    height: 348px;
  }

  .image-responsive-cognite-contact {
    width: 100%;
    height: 100%;
  }

  .custom-form input {
    padding: 6px 12px;
  }
}

@media screen and (max-width: 400px) {
  .hr-line {
    width: 40px;
  }

  .navbar-brand {
    width: 50%;
  }

  .complete-btn {
    padding: 4px;
  }

  .text-small {
    font-size: 10px;
    line-height: unset;
  }

  .subscribe-heading {
    font-size: 15px;
  }

  .news-input {
    font-size: 15px;
    padding: 5px 12px;
  }

  .news-btn {
    font-size: 12px;
    padding: 5px 8px;
  }

  .Footer-link {
    font-size: 12px;
  }
}

.feature-section svg path {
  fill: var(--secondary-color);
}

.feature-section svg:hover path {
  fill: var(--hover-primary);
}